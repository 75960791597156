.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 42vh;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}