.Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 25px;
    padding-top: 50px;
    width: 100%;
    background-color: #ccc;
    color: #394241;
    box-sizing: border-box;
}

.Logo {
    height: 20vw;
    max-height: 90px;
    max-width: 250px;
}

.FlexRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

@media (min-width: 800px) {
  .Logo {
    max-width: 400px;
  }
}