.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.imageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.Item {
    width: 50%;
}

.image {
    width: 50%;
}

.Title {
  font-size: 2.8rem;
  color: #192e5b;
  margin-bottom: 50px;
  text-align: center;
}

@media (max-width: 700px) {
    .container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .imageContainer {
        margin-top: 15px;
        flex-wrap: wrap;
    }

    .Item {
        width: 100%;
    }
}
