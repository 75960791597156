.Header {
  width: 100%;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}

.Left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding-left: 15px;
  margin-bottom: 70px;
  box-sizing: border-box;
}

.Title {
  width: 100%;
  text-align: start;
  font-family: openSans-extra-bold;
  color: #394241;
  font-size: 1.5rem;
  box-sizing: border-box;

}

.SubTitle {
  width: 90%;
  text-align: start;
  margin-top: 20px;
  margin-left: 5px;
  color: #394241;
  box-sizing: border-box;
}

.Right {
  width: 100%;
  height: 300px;
  background-image: url("../../../assets/imgs/header.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.StoresContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.ImageContainer {
  width: 100%;
  height: 70px;
  margin-top: 15px;
  cursor: pointer;
}

.Store {
  height: 100%;
}

.LogoContainer {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
}

.Logo {
  width: 50vw;
  max-width: 900px;
}

@media(min-width: 800px){
  .Header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 50px;
      box-sizing: border-box;
  }

  .Left {
      width: 65%;
      margin-bottom: 0;
      justify-content: center;
      padding-left: 50px;
  }

  .Title {
      font-size: 4.0rem;
  }

  .SubTitle {
      font-size: 1.5rem;
  }    

  .StoresContainer {
      flex-direction: row;
      justify-content: flex-start;
  }
  
  .ImageContainer {
      width: auto;
      cursor: pointer;
  }

  .Store {
      margin-right: 20px;
  }

  .Right {
      width: 35%;
      height: 600px;
  }
}